:root {
  /* Legacy variables */
  --primary: #15171A;
  --secondary: #0057B7;
  --lightbg: #f5f5f5;
  --textgray: #495057;
  --max-width: 100vw;
  --lightgray: #707070;
  --gray-background-color: #f5f5fc;
  --error-background: #ffcccb;
  --green: #43b96f;
  --lightGreen: #ecfff1;
  --red: red;

  /* Branding Colours */
  /* INFO: These variables will be used throughout */
  --clr-brand-default: #0057B7;
  --clr-brand-light: #E5EEF8;
  --clr-brand-dark: #003D80;

  --clr-text-primary: #15171A;
  --clr-text-secondary: #696D70;
  --clr-text-tertiary: #999EA3;

  --clr-grey-default: #C9CDD1;
  --clr-grey-light: #F5F7FA;
  --clr-grey-dark: #B8BBBF;

  --clr-pure-white: #FFFFFF;

  --clr-info-error-default: #D13B3B;
  --clr-info-error-light: #F8E2E2;
  --clr-info-error-dark: #781313;

  --clr-info-success-default: #2C9E82;
  --clr-info-success-light: #DCF5EF;
  --clr-info-success-dark: #0C7057;

  --clr-info-alert-default: #EC9527;
  --clr-info-alert-light: #FCF2E3;
  --clr-info-alert-dark: #8A5006;

  --clr-info-highlight-default: #F5C245;
  --clr-info-highlight-light: #FCEECA;
  --clr-info-highlight-dark: #AC7D08;
}

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");

* {
  font-family: 'Work Sans', sans-serif;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.primarycolor {
  color: var(--secondary);
}

.primary-color-bg {
  background-color: var(--secondary);
}
.bg-lightgray {
  background: #e7e7e7;
}
.body-bg {
  background-color: #f5f5fc;
}

.body-bg1 {
  background-color: var(--lightbg);
}

.secondary-bg {
  background-color: var(--secondary) !important;
}

.calc-table td,
.calc-table th {
  font-size: 0.85rem;
  border: none !important;
  padding: 0.7rem;
}

.calc-result th,
.calc-result td {
  font-size: 1rem;
}
::-ms-fill-lower {
  background: dodgerblue;
}
.font-weight-bold {
  font-weight: bold;
}
.font-size-small {
  font-size: small;
}
.color-white {
  color: #ffffff;
}
.border-dash {
  border-style: dashed;
}
.display-none {
  display: none;
}
.white-bg {
  background-color: #ffffff !important;
}
.tnc-font {
  font-size: 9px;
}
.cursor-pointer {
  cursor: pointer;
}

.attendance-cycle-data {
  border-top: 1px solid gainsboro;
}

.loader-container {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--lightbg);
}

.loader-container div {
  position: fixed;
  top: 43vh;
  left: 45vw;
  color: var(--primary);
  font-size: 7px;
}

.primary-color {
  color: var(--primary) !important;
}
.secondary-color {
  color: var(--secondary) !important;
}

.error-msg {
  font-size: small;
  color: red;
}
.error-border {
  border: 1px solid red;
}
/* CSS for table */
.table-wrapper {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
  background: white;
  border-radius: 8px;
  padding: 20px 50px;
}
.table-title {
  color: var(--primary);
}
.cGkIHG {
  display: none !important;
}
.ejpJLV {
  display: none !important;
}
.hXsnMM {
  display: none !important;
}
.single-line-focus:focus {
  outline: none;
  box-shadow: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.inner-circle-todo {
  height: 20px;
  width: 20px;
  background: var(--secondary);
  margin: 4px;
  border-radius: 50%;
}
.outer-circle-todo {
  height: 30px;
  width: 30px;
  background: white;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid var(--secondary);
}
.todo-text {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
}
.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  stroke: gray;
}
.CircularProgressbar-text {
  fill: yellow;
}
.CircularProgressbar-background {
  fill: green;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.white-color {
  color: var(--white);
}

.light-gray {
  color: var(--lightgray);
}

.green-color {
  color: var(--green);
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 600 !important;
}

.w-unset {
  width: unset !important;
}

.h-unset {
  height: unset !important;
}

.break-word {
  overflow-wrap: break-word !important;
}

.signed-up-btn {
  color: var(--green);
  background-color: var(--lightGreen);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

.not-signed-up-btn {
  color: var(--textgray);
  background-color: var(--gray-background-color);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

.success-btn {
  color: var(--green);
  background-color: var(--lightGreen);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

.error-btn {
  color: var(--red);
  background-color: var(--error-background);
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 10px;
  min-width: 100px;
  text-align: center;
}

button:disabled {
  cursor: not-allowed;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subheading {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
  margin: 0px;
}
.primary-button {
  background-color: var(--secondary);
  color: white;
  height: 48px;
  border-radius: 6px;
  display: block;
  width: 100%;
  transition: 0.5s;
}

.primary-button:hover {
  color: white;
  opacity: 0.8;
}

.modalWrapper {
  background-color: var(--white);
  padding: 50px 20px;
  position: relative;
}

.abs-top-right-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
}

.outline-btn {
  display: inline-block;
  border: 1px solid var(--primary);
  padding: 10px 25px;
}

.b-color-red {
  border-color: red !important;
}
.b-color-secondary {
  border-color: var(--secondary) !important;
}

.color-red {
  color: red !important;
}

.circle-avatar {
  width: 80px;
  height: 80px;
  border: 2px solid var(--white);
  box-shadow: 0px 0px 10px whitesmoke;
  display: inline-block;
  border-radius: 50%;
}

.circle-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.stats-icon-wrapper {
  background: whitesmoke;
  padding: 14px 18px;
  border-radius: 10px;
}

.month-wrapper {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: whitesmoke;
}

.stats-wrapper {
  padding: 30px 25px;
  border-radius: 10px;
}

.bg-transparent {
  background-color: transparent !important;
}

.secondary-button {
  border: 1px solid var(--secondary);
  color: var(--secondary);
  height: 48px;
  border-radius: 5px;
  display: block;
  min-width: 130px;
  transition: 0.5s;
  font-size: 12px;
  font-weight: 600;
}

.secondary-button:hover {
  opacity: 0.8;
  color: var(--secondary);
}

.fw-500 {
  font-weight: 500 !important;
}

.text-primary, .btn-link {
  color: var(--secondary) !important;
}

.input-login {
  background-color: #e4e5e7 !important;
  border: #e4e5e7 !important;
  font-size: small;
  font-weight: 600;
  border-radius: 6px;
}
.input-login:-webkit-autofill,
.input-login:-webkit-autofill:hover,
.input-login:-webkit-autofill:focus,
.input-login:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--primary);
}

.form-signin-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: var(--textgray);
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
}

.form-signin-label-group {
  position: relative;
  margin-bottom: 8px;
}

.dRHSWe {
  border-bottom-color: whitesmoke !important;
  padding: 15px 0px !important;
}

.cTEQON {
  background-color: whitesmoke !important;
  border-bottom: none !important;
}

.sc-AxhCb {
  color: var(--primary) !important;
}

.doBktq {
  padding: 25px 0px !important;
  border-bottom-color: whitesmoke !important;
}

.sc-fzoyAV {
  width: 100% !important;
}

.redirect-arrow {
  position: absolute;
  right: 10px;
  top: 10px
}

@media only screen and (max-width: 425px) {
  .table-wrapper {
    padding: 20px 15px;
  }
}
